import React from "react";
import johnsoncitymeta from '../assets/images/johnsoncitymeta.jpg'
import bgImage from '../assets/images/overlandpark5.jpg'

import {Box, Button, Flex, Grid, Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";
import {BiConversation, BiCut} from "react-icons/bi";
import {GiChoppedSkull, GiLandMine, GiStumpRegrowth, GiTreeBranch, GiTreeDoor, GiTreeRoots} from "react-icons/gi";
import {FaConciergeBell, FaRegHandScissors, FaStar, FaTree, FaUserTie} from "react-icons/fa";
import {MdEmergencyShare, MdHealthAndSafety, MdOutlineEmergency, MdOutlineNaturePeople} from "react-icons/md";
import {AiOutlineScissor} from "react-icons/ai";
import {RiEmotionHappyLine} from "react-icons/ri";
import {PiClockCountdownFill} from "react-icons/pi";
import {posts} from "./posts";
import {faqs} from "./faqs";

const phoneNumber = "(614) 333-0588";
const telLink = "tel:6143330588";
const phoneNumberWithDashes = "614-333-0588";

export const SiteData = {
    hoursOfOperation: "24/7",
    "city": "Columbus, OH",
    phoneNumber,
    phoneNumberWithDashes,
    telLink,
    "buttonCtaText": () => <Flex>Discover Premier Tree Care in Columbus. Book Your&nbsp;<Text
        textDecoration={'underline'} display={'inline'}>Free Consultation Today!</Text></Flex>,
    "keywords": 'Tree Care, services, professional, affordable, experienced, trimming, Columbus, OH.',
    "footerText": 'Columbus Tree Service, All rights reserved.',
    "ogImage": johnsoncitymeta,
    "navLinks": [
        {
            "title": 'Home',
            "href": '/'
        },
        {
            "title": 'Services',
            "href": '/services/'
        },
        {
            "title": 'Blog',
            "href": '/blog/'
        },
        {
            "title": 'About',
            "href": '/about/'
        },
        {
            "title": 'Contact Us',
            "href": '/contact/'
        },
        {
            "title": 'Careers',
            "href": '/careers/',
            "hideFromTopNav": true
        },
    ],
    "signupForm": {
        "intro": () => <Text>Welcome to Columbus Tree Service, your trusted partner for exceptional tree care in
            Columbus, Ohio. Our team of seasoned arborists understands the unique flora and landscape challenges of the
            Columbus area, and we are excited to offer our expertise to you. For top-quality tree services, reach out to
            us at <Link href={telLink} textDecoration={'underline'}>{phoneNumber}</Link> today!</Text>,
        "servicesTitle": 'Our Services:',
    },
    "homepage": {
        "bgImage": bgImage,
        "url": 'https://www.topcolumbustreeservice.com',
        "metaData": {
            "title": `Columbus Tree Service: Your Local Arborist Experts | Call ${phoneNumber}`,
            "description": 'Columbus Tree Service is your go-to for expert tree care. Our skilled team provides a comprehensive range of tree services to keep your Columbus property looking its best. Contact us today!',
        },
        "h1": 'Professional Tree Care Services in Columbus, OH',
        "subtitle": 'Comprehensive Tree Care - Trimming, Removal, Health Consulting, and More',
        "servicesTitle": 'Why Columbus Residents Rely on Our Tree Services',
        "values": [
            {
                "id": 0,
                "number": "10,000+",
                "name": "Trees Serviced",
                "icon": GiTreeBranch,
            },
            {
                "id": 4,
                "number": <><Flex my={3}><FaStar fontSize={'1.9rem'}/><FaStar fontSize={'1.9rem'}/><FaStar
                    fontSize={'1.9rem'}/><FaStar fontSize={'1.9rem'}/><FaStar fontSize={'1.9rem'}/></Flex></>,
                "name": "Certified Arborists",
                "icon": FaUserTie
            },
            {
                "id": 2,
                "number": "99%",
                "name": "Customer Satisfaction",
                "icon": RiEmotionHappyLine,
            },
            {
                "id": 3,
                "number": "24/7",
                "name": "Emergency Availability",
                "icon": PiClockCountdownFill,
            },
        ],
        "services": [
            {
                "id": '0',
                "text": () => <Text><b>Skilled Arborists:</b> Our Columbus-based team of certified arborists brings
                    unparalleled expertise to every <a href={"https://www.cranefamilytree.com/"} target={"_blank"} rel={"noreferrer"}>tree service</a> task, ensuring the unique needs of the Columbus region
                    are met.</Text>
            },
            {
                "id": '1',
                "text": () => <Text><b>Safe Tree Services:</b> Your safety is our priority. We follow strict safety
                    protocols, utilizing advanced technology to ensure safe and efficient tree care for every
                    project.</Text>
            },
            {
                "id": '2',
                "text": () => <Text><b>Competitive Pricing:</b> We offer transparent and affordable pricing for all our
                    tree care services. Get in touch for a custom quote that suits your specific Columbus tree care
                    needs.</Text>
            },
            {
                "id": '3',
                "text": () => <Text><b>Complete Tree Care:</b> From tree removal and preservation to general management,
                    our Columbus team offers a wide range of services including fertilization, pruning, stump grinding,
                    and emergency storm damage response.</Text>
            },
            {
                "id": '4',
                "text": () => <Text><b>Local Columbus Expertise:</b> We are proud Columbus locals. Our deep
                    understanding of the local trees and landscape ensures top-tier service for our community.</Text>
            }
        ],
        "content": [
            <Box p={5}>
                <Heading>Welcome to the Ultimate Tree Care Experience with Top Columbus Tree Service!</Heading>
                <Text mt={3}>
                    Greetings from the heart of Columbus, Ohio! If you’re scouring the internet for stellar tree care
                    services, your search ends here. Top Columbus Tree Service is your premier destination for all
                    things trees. Our mission? To ensure the health and beauty of your trees while keeping our processes
                    eco-friendly and your landscapes breathtaking.
                </Text>
                <Heading mt={5}>Our Wide Array of Tree Care Services</Heading>
                <Box mt={3}>
                    <Text>
                        <Link href='/tree-trimming/'>Tree Trimming in Columbus:</Link> Picture this:
                        trees in your yard looking as neat as a pin, healthy, and more alive than ever. That’s what our
                        tree trimming service offers. Trimming isn’t just about aesthetics; it’s crucial for removing
                        potentially dangerous branches and promoting tree health. With us, you get a service that’s not
                        just about cutting branches but ensuring your trees are set for a long, healthy life.
                    </Text>
                    <Text mt={3}>
                        <Link href='/stump-grinding/'>Stump Grinding and Removal Near Me:</Link> Stumps
                        can be an eyesore and a hazard in your beautiful yard. But with our top-notch stump grinding
                        services, they don’t have to be. We use the latest techniques and equipment to remove stumps
                        efficiently, clear the way for new projects, or just give the yard a cleaner look.
                    </Text>
                    <Text mt={3}>
                        <Link href='/tree-planting/'>Tree Planting and Transplanting in Columbus,
                            Ohio:</Link> Are you adding new trees to your landscape? Or you may need to move an existing
                        one to a new spot. Our experts are here to guide you every step of the way, from selecting the
                        suitable species that thrive in Ohio to ensuring they’re planted or transplanted correctly for
                        optimal growth.
                    </Text>
                    <Text mt={3}>
                        <Link href='/tree-health-consulting/'>Tree Health Consulting:</Link> Our
                        certified arborists are like tree doctors, ready to offer you the best advice on caring for your
                        trees. Whether you’re dealing with a sick tree or want to ensure your trees remain healthy, our
                        consulting services are invaluable.
                    </Text>
                    <Text mt={3}>
                        <Link href='/emergency-tree-services/'>Emergency Tree Services:</Link> Storms
                        and unexpected events can leave you needing urgent tree care. We’re always ready to respond,
                        providing swift, efficient services to ensure your safety and property.
                    </Text>
                    <Text mt={3}>
                        <Link href='/arborist-consultations/'>Arborist Consultations:</Link> Dive
                        deeper into the health and management of your trees with our arborist consultations. We offer
                        expert advice on risk assessment, health management, and more, tailored to your needs.
                    </Text>
                    <Text mt={3}>
                        <Link href='/land-clearing/'>Land Clearing Services:</Link> Planning a big
                        project requires a clean slate. Our eco-friendly land-clearing services are designed to prepare
                        your property efficiently and responsibly, making way for whatever dream project you have in
                        mind.
                    </Text>
                    <Text mt={3}>Comprehensive Tree Maintenance: Imagine having a team that looks after your trees
                        through every season, ensuring they’re always at their best. That’s what our tree maintenance
                        program is all about. From nutrient management to pest control and disease prevention, we cover
                        all bases to keep your trees thriving all year round.</Text>
                </Box>
                <Heading mt={5}>Eco-Friendly Practices: Our Green Commitment</Heading>
                <Text mt={3}>
                    In a world where environmental care is more important than ever, we stand firm in our commitment to
                    sustainability. Our services are designed not just with your landscape’s beauty in mind but with a
                    deep respect for ecological balance. By choosing us, you’re choosing a partner who cares about the
                    future of our planet as much as you do.
                </Text>
                <Heading mt={5}>Why Top Columbus Tree Service Stands Out</Heading>
                <Text mt={3}>
                    Opting for Top Columbus Tree Service means choosing expertise, reliability, and a custom approach to
                    tree care. We’re not just a local tree service; we’re your neighbors committed to making our
                    community greener and more beautiful. With us, you get:
                </Text>
                <Box pl={4} mt={2}>
                    <Text>• Expertise in a broad range of tree care services.</Text>
                    <Text>• A commitment to eco-friendly and sustainable practices.</Text>
                    <Text>• Timely, professional service you can rely on.</Text>
                    <Text>• Customized solutions tailored to your unique needs.</Text>
                    <Text>• Exceptional value without compromising on quality.</Text>
                </Box>
                <Heading mt={5}>Ready to Serve Your Tree Care Needs</Heading>
                <Text mt={3}>
                    No matter what your tree care needs are—routine maintenance, emergency services, or advice on how to
                    care for your trees—we’re here for you. Our team of experts is ready to provide you with
                    professional, reliable, and practical solutions that ensure the health of your trees and the safety
                    of your property.
                </Text>
                <Box mt={4} display="flex" flexDirection="column" alignItems="start">
                    <Button colorScheme="teal" _hover={{color: "white"}} mt={2} as={'a'} href={telLink}>Reach Out to Top
                        Columbus Tree
                        Service</Button>
                </Box>
            </Box>
        ],
        additionalContent:
            <>
                <Box p={5}>
                    <Heading as="h1" size="xl" mb={4}>Welcome to Top Columbus Tree Service</Heading>
                    <Text mb={4}>
                        At Top Columbus Tree Service, we're not just another tree care company but a dedicated team
                        passionate about bringing life and vitality to your outdoor spaces. Founded in Columbus, Ohio,
                        our journey began with a simple mission: to deliver unparalleled tree care services that
                        prioritize the health of your trees and the beauty of your landscape, all while upholding our
                        commitment to eco-friendly practices. Our team comprises certified arborists and seasoned tree
                        care professionals who bring years of experience, advanced techniques, and a deep love for
                        nature to every project. We pride ourselves on our ability to tackle any tree-related challenge,
                        big or small, and our unwavering dedication to customer satisfaction and environmental
                        stewardship.
                    </Text>
                    <Heading as="h2" size="lg" mb={2}>Why Tree Care is Important</Heading>
                    <Text mb={4}>
                        Proper tree care is essential for maintaining your property's health, safety, and aesthetics.
                        Trees not only enhance the beauty of your landscape but also provide valuable shade, improve air
                        quality, and contribute to the local ecosystem. Neglected trees can become a safety hazard,
                        while well-maintained trees add curb appeal and can increase property values. At Top Columbus
                        Tree Service, we understand trees' critical role in our environment and lives. We offer
                        comprehensive tree care services to ensure your trees are healthy, safe, and beautiful. Whether
                        you need routine maintenance, disease management, or emergency tree services, we're here to help
                        your trees thrive.
                    </Text>
                    <Heading as="h2" size="lg" mb={2}>Our Services</Heading>
                    <Text mb={2}>
                        <Link href="/tree-trimming/">Tree Trimming and Pruning:</Link> Our expert tree
                        trimming services are tailored to enhance the health and appearance of your trees, removing
                        dangerous limbs and promoting robust growth.
                    </Text>
                    <Text mb={2}>
                        <Link href="/stump-grinding/">Stump Grinding:</Link> Say goodbye to unsightly
                        stumps with our efficient stump grinding services, making your yard safer and more inviting.
                    </Text>
                    <Text mb={2}>
                        <Link href="/tree-planting/">Tree Planting and Transplanting:</Link> Enhance
                        your property with new trees or relocate existing ones with the help of our tree planting
                        experts, ensuring they thrive in their new home.
                    </Text>
                    <Text mb={2}>
                        <Link href="/tree-health-consulting/">Tree Health Consulting:</Link> Our
                        certified arborists provide invaluable advice on tree care, helping you make informed decisions
                        to keep your trees in peak condition.
                    </Text>
                    <Text mb={2}>
                        <Link href="/emergency-tree-services/">Emergency Tree
                            Services:</Link> Available around the clock, our team is ready to address urgent tree care
                        needs to ensure your safety and protect your property.
                    </Text>
                    <Text mb={2}>
                        <Link href="/arborist-consultations/">Arborist Consultations:</Link> Get
                        personalized advice from our professional arborists to help you better understand the health and
                        management of your trees.
                    </Text>
                    <Text mb={4}>
                        <Link href="/land-clearing/">Land Clearing:</Link> Prepare your property for
                        new projects with our eco-friendly land clearing services, designed for efficiency and
                        environmental responsibility.
                    </Text>
                    <Text mb={2}>
                        Comprehensive Tree Maintenance: From nutrient management to pest control, our year-round maintenance
                        program ensures your trees remain a vibrant part of your landscape.
                    </Text>
                    <Text mb={4}>
                        Stay informed with our regularly updated blog featuring articles on tree care topics. Whether
                        you're interested in learning about the native trees of Ohio, how to protect your trees from
                        pests and diseases, or tips for selecting the right tree service provider, our blog is your
                        go-to resource for expert advice and insights.
                    </Text>

                    <Box as="section" pt={10} pb={10}>
                        <Heading as="h2" size="lg" mb={4}>
                            Top Columbus Tree Service Frequently Asked Questions (FAQs)
                        </Heading>
                        <Grid templateColumns={{base: "repeat(1, 1fr)", md: "repeat(2, 1fr)"}} gap={6}>
                            {faqs.map(({question, answer}, index) => (
                                <Box
                                    p={5}
                                    borderWidth="1px"
                                    borderRadius="lg"
                                    key={index}
                                >
                                    <Heading as={"h3"} fontSize="xl">{question}</Heading>
                                    <Text mt={4}>{answer}</Text>
                                </Box>
                            ))}
                        </Grid>
                    </Box>

                    <Text mb={4}>
                        At Top Columbus Tree Service, we believe in giving back to the community that has supported us.
                        Learn about our involvement in local conservation efforts, community beautification projects,
                        and partnerships with environmental organizations. We're committed to making Columbus a greener,
                        more beautiful place to live, one tree at a time.
                    </Text>
                    <Heading as="h2" size="lg" mb={2}>Your Safety and Peace of Mind</Heading>
                    <Text mb={4}>
                        Your safety and peace of mind are our top priorities. Our section on safety and insurance
                        provides detailed information on the measures we take to ensure the safety of our clients, their
                        property, and our team during every project. Learn about our comprehensive insurance coverage
                        and our commitment to following industry-best safety practices. Ensuring the well-being of
                        everyone involved and the environment is at the core of everything we do at Top Columbus Tree
                        Service.
                    </Text>
                    <Text>
                        Whether you're looking for expert advice, need emergency tree services, or want to enhance the
                        beauty and safety of your property with our tree care services, <Link href="/contact/"
                    >contact
                        us</Link> today. Let's work together to make your outdoor spaces beautiful, healthy, and safe
                        for everyone to enjoy.
                    </Text>
                </Box>
            </>,
        "footerHeading": 'Contact Columbus Tree Service',
        "footerText": () => <>For professional tree services in Columbus and the surrounding areas, reach out to us
            today. Call or fill out our <Link href={'/contact'}>online contact form</Link> for non-emergency inquiries
            and someone from our team will reach out to you. Our team is here for you and all of your tree care
            needs!</>,
        "footerText2": 'Your Tree\'s Health and Beauty is Our Priority',
        "servicesHeader": 'Our Extensive Range of Tree Services for All Your Arboricultural Needs',
        "servicesSubtitle": () => <Text color="muted" fontSize={{base: 'lg', md: 'xl'}}>Explore our <Link
            href={'/services'}>suite of services</Link> – from emergency tree removal and tree health maintenance to
            lawn care service, Columbus Tree Service is your one-stop solution for all tree-related needs</Text>,
        "featuredServices": [
            {
                "name": 'Tree Trimming',
                "description": 'We offer complete tree trimming and pruning services to maintain the health and aesthetics of your trees, contributing to the overall beauty of your outdoor spaces.',
                "icon": BiCut,
                "path": '/tree-trimming/',
                "linkTitle": 'Tree Trimming'
            },
            {
                "name": 'Stump Grinding',
                "description": 'Specializing in stump grinding and removal, we aim to safely and efficiently clear unwanted stumps from your yard, improving the usability of your landscape.',
                "icon": GiTreeRoots,
                "path": '/stump-grinding/',
                "linkTitle": 'Stump Grinding'
            },
            {
                "name": 'Tree Maintenance',
                "description": 'Looking to maintain your trees\' health long term? We offer a diverse range of tree health and maintenance services tailored to the unique needs of your trees.',
                "icon": FaTree,
                "path": '/tree-health-consulting/',
                "linkTitle": 'Tree Maintenance'
            },
            {
                "name": 'Tree Planting and Transplanting',
                "description": 'Our tree planting and transplanting service ensures optimal care and handling of your trees during transit, ideal for those wishing to relocate trees without causing them distress.',
                "icon": GiTreeDoor,
                "path": '/tree-planting/',
                "linkTitle": 'Tree Planting and Transplanting'
            },
            {
                "name": 'Tree Health Consulting',
                "description": 'Need professional advice on your trees? Our certified arborist consulting service can equip you with the necessary insight and recommendations to make informed decisions about your tree care.',
                "icon": FaConciergeBell,
                "path": '/tree-health-consulting/',
                "linkTitle": 'Tree Health Consulting'
            },
            {
                "name": 'Emergency Tree Services',
                "description": 'We offer prompt and reliable emergency tree services, prepared to address urgent tree care needs caused by storms, damage, or other unforeseen events.',
                "icon": MdOutlineEmergency,
                "path": '/emergency-tree-services/',
                "linkTitle": 'Emergency Tree Services'
            },
            {
                "name": 'Arborist Consultations',
                "description": 'Gain expert insights and solutions for your trees with our professional arborist consultations, focusing on tree health, risk assessment, and sustainable management.',
                "icon": MdOutlineNaturePeople,
                "path": '/arborist-consultations/',
                "linkTitle": 'Arborist Consultations'
            },
            {
                "name": 'Land Clearing',
                "description": 'Our comprehensive land clearing services prepare your property for construction, landscaping, or agricultural projects, ensuring an efficient and eco-friendly process.',
                "icon": FaRegHandScissors,
                "path": '/land-clearing/',
                "linkTitle": 'Land Clearing'
            },
        ]
    },
    "careers": {
        "url": "https://www.topcolumbustreeservice.com/careers/",
        "metaData": {
            "title": "Join Our Team - Career Opportunities at Columbus Tree Service, Columbus, OH",
            "description": "Explore career opportunities in arboriculture with Columbus Tree Service. Join our team of tree care experts in Columbus, OH. We're looking for passionate individuals dedicated to quality tree care."
        },
        "h1": "We're Hiring - Become a Part of Columbus Tree Service",
        "h2": [
            "Why Join Columbus Tree Service?",
            "Current Openings for Tree Care Specialists",
            "Our Commitment to Employee Development and Satisfaction"
        ],
        "headingText": {
            "whyJoin": "Discover a workplace where your passion for trees and the environment is valued. Join a team committed to providing exceptional tree care services in Columbus, OH.",
            "openings": "Browse our current job openings for arborists, tree climbers, and grounds maintenance staff. Find a role that aligns with your skills and passion for tree care.",
            "commitment": "At Columbus Tree Service, we believe in investing in our team's expertise and providing a supportive, dynamic work environment. Your growth in the field of arboriculture is integral to our success."
        },
        "content": [
            <Text>Welcome to the Careers section at <Link href={'/'}>Columbus Tree Service</Link>, where your career in
                arboriculture and tree care can flourish. We're a family of professionals dedicated to the highest
                standards in tree services. Join us in making a positive impact in Columbus, OH.</Text>,
            <Text>At <Link href={'/'}>Columbus Tree Service</Link>, we understand that our strength comes from our
                people. We are looking for dedicated individuals who share our commitment to exceptional tree care.
                Whether you're an experienced arborist or eager to learn, we offer a workplace where your skills are
                appreciated and nurtured.</Text>,
            <Text>Our employees enjoy a culture that promotes personal and professional growth. We provide comprehensive
                training, competitive compensation, and the opportunity to work with advanced tree care equipment. Our
                goal is to create a safe, rewarding, and enjoyable workplace for everyone on our team.</Text>,
            <Text>As a key part of Columbus's community, we take pride in serving our neighbors with integrity and
                professionalism. Our commitment to service excellence extends to our team members, whom we support with
                a positive work environment, respectful teamwork, and opportunities for career advancement.</Text>,
            <Text>Are you ready to join a leading tree service provider in Columbus, OH? Check out our current job
                openings and find where you fit in our growing team. From skilled tree climbers to customer service
                representatives, there's a place for you at Columbus Tree Service.</Text>,
            <Text><a href='https://www.topcolumbustreeservice.com/careers/'>Discover your next career
                opportunity</a> with us. Be a part of a team that's dedicated to preserving and enhancing the urban
                canopy. At <Link href={'/'}>Columbus Tree Service</Link>, we're more than just a company; we're a
                community. Apply today and grow your career in tree care.</Text>,
        ]
    },
    "about": {
        "url": "https://www.topcolumbustreeservice.com/about/",
        "metaData": {
            "title": "Expert Tree Care Services in Columbus | (614) 333-0588",
            "description": "Learn about our dedicated tree care services in Columbus. From precision pruning to emergency tree removal, our experienced team is here for all your arboricultural needs.",
        },
        "h1": "Professional Tree Services in Columbus, OH",
        "h2": [
            "Emergency Tree Services: Available 24/7 for urgent tree care and removal needs.",
            "Experienced Arborists: Our team of certified arborists is trained and equipped to provide expert tree care.",
            "Comprehensive Tree Care: Offering a wide range of services from tree health assessments to stump grinding.",
            "Eco-Friendly Practices: We prioritize the health of your trees and the environment in every service we provide.",
            "Competitive Pricing: Our services are affordably priced, ensuring quality tree care is accessible to all."
        ],
        "headingText": {
            "commitment": "Dedicated to delivering exceptional tree care services, ensuring the health and beauty of Columbus's urban forest.",
            "team": "Our team at Columbus Tree Service is composed of passionate arborists and tree care professionals, committed to excellence in every project.",
            "services": "Columbus Tree Service offers a full spectrum of tree care services. From routine pruning and maintenance to emergency tree removals and disease management, our services cater to all your tree care needs. We use the latest techniques and equipment to ensure the health and safety of your trees and property. Whether it's a residential garden or a commercial landscape, our team provides professional, efficient, and eco-friendly services."
        },
        "services": [
            {
                "id": "0",
                "title": "Tree Pruning & Trimming",
                "href": "/tree-pruning",
                "icon": AiOutlineScissor,
                "description": (
                    <Text>
                        Precision pruning and trimming to maintain the health and appearance of your trees, performed by
                        our skilled arborists.
                    </Text>
                ),
            },
            {
                "id": "1",
                "title": "Tree Removal",
                "href": "/tree-removal",
                "icon": GiChoppedSkull,
                "description": (
                    <Text>
                        Safe and efficient tree removal services for hazardous or unwanted trees, ensuring your
                        property’s safety and aesthetic appeal.
                    </Text>
                ),
            },
            {
                "id": "2",
                "title": "Stump Grinding & Removal",
                "href": "/stump-grinding",
                "icon": GiStumpRegrowth,
                "description": (
                    <Text>
                        Comprehensive stump grinding and removal services to clear your landscape and prepare it for new
                        growth or landscaping projects.
                    </Text>
                ),
            },
            {
                "id": "3",
                "title": "Emergency Tree Services",
                "href": "/emergency-tree-services",
                "icon": MdEmergencyShare,
                "description": (
                    <Text>
                        Prompt and reliable emergency tree services for storm damage, fallen trees, or urgent tree care
                        needs.
                    </Text>
                ),
            },
            {
                "id": "4",
                "title": "Tree Health Consulting",
                "href": "/tree-health-consulting",
                "icon": MdHealthAndSafety,
                "description": (
                    <Text>
                        Expert assessments to diagnose and treat tree diseases and pests, ensuring the longevity and
                        vitality of your trees.
                    </Text>
                ),
            },
            {
                "id": "5",
                "title": "Arborist Consultations",
                "href": "/arborist-consultations",
                "icon": BiConversation,
                "description": (
                    <Text>
                        Professional arborist consultations to advise on tree care, planting, and maintenance for
                        healthy and sustainable landscapes.
                    </Text>
                ),
            },
            {
                "id": "6",
                "title": "Land Clearing & Lot Preparation",
                "href": "/land-clearing",
                "icon": GiLandMine,
                "description": (
                    <Text>
                        Efficient land clearing services for construction projects, landscaping, and property
                        development, with a focus on environmental responsibility.
                    </Text>
                ),
            },
        ],
    },
    "testimonials": {
        "h1": 'Testimonials',
        "heading": 'Hear from Our Satisfied Customers – Your Top Choice for Tree Services in Columbus',
        "testimonials": [
            {
                "avatarUrl": 'https://i.pravatar.cc/150?u=a042581f4e29026704d',
                "name": 'Sarah P.',
                "logo": undefined,
                "title": 'Columbus, OH',
                "quote":
                    '"I needed a large tree trimmed in my backyard and Columbus Tree Service did an incredible job. They were professional, efficient, and left my yard looking immaculate. Highly recommend for anyone needing expert tree care in Columbus."'
            },
            {
                "avatarUrl": 'https://i.pravatar.cc/150?u=a0425d',
                "name": 'Mike D.',
                "logo": undefined,
                "title": 'Columbus, OH',
                "quote":
                    '"After a storm, a tree on our property was dangerously leaning towards our house. Columbus Tree Service responded quickly and safely removed the tree. Their quick action and professionalism were outstanding."'
            },
            {
                "avatarUrl": 'https://i.pravatar.cc/150?u=a0804d',
                "name": 'Emily R.',
                "logo": undefined,
                "title": 'Columbus, OH',
                "quote":
                    '"Columbus Tree Service transformed our garden with their tree planting services. Their advice on tree selection and care has made our outdoor space more enjoyable and beautiful. Exceptional service and knowledge!"'
            },
        ]
    },
    "services": {
        "metaData": {
            "title": 'Top-Quality Tree Care Services in Columbus | Columbus Tree Service',
            "description": 'Explore our comprehensive range of tree care services in Columbus. From expert pruning to swift tree removal, our dedicated team is here for all your arboriculture needs. Contact us for prompt tree care solutions.',
        },
        "h1": 'Expert Tree Services in Columbus, OH – Your Local Specialists',
        "h2": 'The Tree Care Services We Provide',
        "subtext": 'At Columbus Tree Service, our mission is to offer a variety of tree care services that focus on the health and aesthetics of your landscape at competitive rates.',
        "footerHeading": 'Ready to Enhance Your Landscape?',
        "footerText2": () => <>Reach out to us at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> or use our
            online contact form for a free quote. Experience the Columbus Tree Service difference today!</>,
        "footerText": () => <>Remember, at Columbus Tree Service, your trees are our top priority. For exceptional tree
            care in Columbus, OH, trust our skilled team. We're just a call away!</>,
        "content": <Box>
            <Stack spacing={5}>
                <Heading as="h2" size="xl">Columbus Tree Service: Superior Tree Care in Columbus, OH</Heading>
                <Text>At Columbus Tree Service, we're committed to providing top-notch tree care and maintenance
                    services in Columbus, OH. Whether you need routine pruning, emergency tree removal, or expert
                    arborist advice, our certified professionals are ready to offer safe, efficient, and environmentally
                    responsible services. We pride ourselves on our commitment to customer satisfaction and our ability
                    to handle a wide range of tree-related needs.</Text>

                <Heading as="h3" size="lg">Why Choose Columbus Tree Service?</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Experienced Arborists:</b> Our certified arborists bring extensive knowledge and
                        skill to every project, ensuring the highest standards of tree care.</Text></ListItem>
                    <ListItem><Text><b>Advanced Tree Care Techniques:</b> We utilize modern, eco-friendly methods and
                        equipment to provide comprehensive tree care, prioritizing the health of your trees and the
                        environment.</Text></ListItem>
                    <ListItem><Text><b>24/7 Emergency Services:</b> Understanding that tree emergencies can happen at
                        any time, we offer round-the-clock services for urgent tree care needs.</Text></ListItem>
                    <ListItem><Text><b>Competitive Pricing:</b> We believe in offering high-quality services at fair and
                        transparent prices, delivering value to every client.</Text></ListItem>
                    <ListItem><Text><b>Local Columbus Expertise:</b> Familiar with Columbus's specific tree species and
                        challenges, we provide tailored care for optimal tree health and aesthetics.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Comprehensive Tree Care & Maintenance Services</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Tree Trimming and Pruning:</b> Our precision trimming and pruning services
                        enhance the health and beauty of your trees, contributing to a safer and more appealing
                        landscape.</Text></ListItem>
                    <ListItem><Text><b>Emergency Tree Services:</b> We respond swiftly to emergency situations, expertly
                        handling storm-damaged trees, fallen limbs, and other urgent scenarios with professionalism and
                        care.</Text></ListItem>
                    <ListItem><Text><b>Stump Grinding and Removal:</b> Our effective stump grinding services clear your
                        property, facilitating new landscaping opportunities.</Text></ListItem>
                    <ListItem><Text><b>Arborist Consultations:</b> Gain insights from our arborists on tree health, risk
                        assessment, and proper tree care strategies.</Text></ListItem>
                    <ListItem><Text><b>Land Clearing:</b> We prepare your property for new construction, landscaping, or
                        agricultural use with efficient and eco-friendly land clearing services.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Tailored Solutions for Your Trees</Heading>
                <Text>Understanding that each tree and property has unique needs, our customized approach ensures every
                    aspect of your tree care is handled with attention to detail and tailored to your specific
                    requirements.</Text>

                <Heading as="h3" size="lg">Commitment to Safety and Environmental Care</Heading>
                <Text>At Columbus Tree Service, your safety and the health of your trees are our primary concerns. We're
                    dedicated to providing services that meet and exceed industry standards for safety and environmental
                    responsibility.</Text>

                <Heading as="h3" size="lg">Contact Us for Professional Tree Care</Heading>
                <Text>For expert tree care and maintenance services in Columbus, OH, trust Columbus Tree Service.
                    Contact us today for professional, reliable, and eco-conscious tree care solutions.</Text>
            </Stack>
        </Box>,
        "services": [
            {
                "id": '0',
                "text": () => <Text><b><Link href={'/tree-pruning'}>Tree Pruning</Link> and <Link
                    href={'/tree-trimming'}>Trimming:</Link></b> Our team offers expert pruning and trimming, essential
                    for maintaining tree health and enhancing aesthetic appeal.</Text>
            },
            {
                "id": '1',
                "text": () => <Text><b><Link href={'/stump-grinding'}>Stump Grinding:</Link></b> Remove unsightly or
                    hazardous tree stumps from your landscape efficiently with our stump grinding service.</Text>
            },
            {
                "id": '2',
                "text": () => <Text><b><Link href={'/tree-removal'}>Tree Removal:</Link></b> For trees posing a threat
                    due to structural issues or decay, our team ensures safe and professional removal.</Text>
            },
            {
                "id": '3',
                "text": () => <Text><b><Link href={'/tree-health-consulting'}>Tree Health Consulting:</Link></b> Our
                    arborists are ready to assess your trees' health and provide specific treatments and care
                    recommendations.</Text>
            },
        ],
        "whyh2": 'Why Choose Columbus Tree Service?',
        "whySubtext": () => <>Our experts at Columbus Tree Service have been providing unparalleled professional tree
            care services in Columbus for years. We align with your unique needs to ensure proper care of your trees and
            landscape. Reach out to us at <Link href={phoneNumber}
                                                textDecoration={'underline'}>{phoneNumberWithDashes}</Link>.</>,
        "whyServices": [
            {
                "id": '0',
                "text": () => <Text><b>Experienced Professionals:</b> Our team consists of trained professionals with
                    years of expertise in tree care, ready to handle any project, big or small.</Text>
            },
            {
                "id": '1',
                "text": () => <Text><b>Unmatched Service:</b> We prioritize our customers and their needs, ensuring
                    availability throughout the project and working diligently until their satisfaction is
                    achieved.</Text>
            },
            {
                "id": '2',
                "text": () => <Text><b>Reliable and Efficient:</b> With years of consistent, top-notch service to our
                    community, we have built a strong reputation for reliability and efficiency.</Text>
            },
            {
                "id": '3',
                "text": () => <Text><b>Local Columbus Experts:</b> Our deep understanding of Columbus's botany,
                    topography, and landscape provides our customers with an unmatched level of expertise.</Text>
            },
        ]
    },
    "contact": {
        "url": 'https://www.topcolumbustreeservice.com/contact/',
        "metaData": {
            "title": `Contact Columbus Tree Service | Call ${phoneNumber}`,
            "description": 'Connect with Columbus Tree Service for expert tree care services in Columbus, OH. We are dedicated to maintaining the health and beauty of your trees.',
        },
        "h1": 'Columbus Tree Service',
        "subtitle": 'Thank you for choosing Columbus Tree Service. We are a committed tree service company operating in Columbus, Ohio, offering reliable tree care services to our valued customers. Our professional and friendly team is eager to assist with all your tree care requirements.',
        "sectionHeading": 'Why Choose Columbus Tree Service?',
        "signUpDescription": 'To reach out to us online, kindly fill out the form below. We will respond as promptly as possible.',
        "feedbackText": 'Your feedback is valuable to us. If you have any questions or concerns, we would be delighted to hear from you. Ensuring your satisfaction is our highest priority.',
        "closingText": () => <>Providing trusted tree care services in Columbus, OH.</>,
        "valueProps": [
            {
                "title": 'Certified Arborists:',
                "description": 'Our team of certified arborists possesses years of experience and knowledge in providing top-notch tree care. We pride ourselves on delivering exceptional service to ensure the well-being and aesthetics of your trees.',
            },
            {
                "title": 'Comprehensive Tree Services:',
                "description": 'From regular tree maintenance to removal and stump grinding, we offer an extensive range of services. Our skilled team is equipped with the right tools to cater to all your tree care needs.'
            },
            {
                "title": 'Modern Equipment:',
                "description": 'We utilize high-quality, up-to-date equipment to ensure efficient and secure tree care services. Our equipment allows us to handle trees of any size and condition with ease.'
            },
            {
                "title": 'Environmentally Aware:',
                "description": 'Understanding the ecological significance of trees, we are committed to delivering sustainable services. We follow best practices to minimize environmental impact during tree removal or any other services.'
            },
            {
                "title": 'Customer Satisfaction:',
                "description": 'At Columbus Tree Service, exceeding your expectations is our aim. We are dedicated to providing superior customer service and ensuring a smooth experience from start to finish.'
            },
        ]
    },
    "blog": {
        "url": 'https://www.topcolumbustreeservice.com/blog/',
        "metaData": {
            "title": 'Columbus Tree Service: Expert Tips and Advice | Blog',
            "description": 'Discover expert advice for tree care, trimming, and removal in Columbus, OH. Stay updated with our blog for top-quality tree care services and tips.',
        },
        "h1": 'Columbus Tree Service Blog - Tips and Insights',
        "h2": 'Our Latest Articles',
        "heading": 'Stay informed with the latest tree care tips and advice from Columbus Tree Service. Our blog is your resource for everything related to tree care in Columbus, OH.',
        "posts": posts
    },
    "treePruning": {
        "url": 'https://www.topcolumbustreeservice.com/tree-pruning',
        "metaData": {
            "title": 'Professional Tree Pruning in Columbus, OH | Columbus Tree Service',
            "description": 'Enhance the health and beauty of your trees with our expert tree pruning services in Columbus, OH. Contact us for specialized care!'
        },
        "h1": 'Tree Pruning Services in Columbus, OH',
        "subtitle": 'Maximize the health and appearance of your trees with our professional tree pruning services in Columbus. Our skilled arborists ensure proper pruning for the vitality and beauty of your trees.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Tree Pruning Services</Heading>
                <Text>At Columbus Tree Service, we offer comprehensive tree pruning services to enhance the health and
                    aesthetics of your trees. Our services include health pruning to remove dead or diseased branches,
                    structural pruning for strong tree growth, aesthetic pruning to improve tree shape and appearance,
                    and safety pruning to remove potential hazards.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Choose Columbus Tree Service?</Heading>
                <Text>Choose Columbus Tree Service for experienced arborists, quality service, local Columbus expertise,
                    and commitment to customer satisfaction. Our team is well-equipped to provide exceptional tree
                    pruning services tailored to your needs.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Schedule Your Tree Pruning Service</Heading>
                <Text>Contact us today at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> to schedule your tree
                    pruning service in Columbus, OH. Experience the difference with Columbus Tree Service.</Text>
            </Stack>
        </Box>
    },
    "treeRemoval": {
        "url": 'https://www.topcolumbustreeservice.com/tree-removal/',
        "metaData": {
            "title": 'Safe and Efficient Tree Removal in Columbus, OH | Columbus Tree Service',
            "description": 'Columbus Tree Service offers expert tree removal services in Columbus, OH. Contact us for reliable and efficient tree removal.'
        },
        "h1": 'Expert Tree Removal Services in Columbus, OH',
        "subtitle": 'Professional and safe tree removal services are essential in Columbus for managing hazardous or unwanted trees. Our team ensures the safety and aesthetic appeal of your property.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Tree Removal Services</Heading>
                <Text>We provide comprehensive tree removal services, including dangerous tree removal, dead tree
                    removal, diseased tree removal, and obstructive tree removal. Our team handles each project with
                    utmost safety and efficiency.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Choose Columbus Tree Service?</Heading>
                <Text>Choose us for expert arborists, quality service, local expertise, and a commitment to customer
                    satisfaction. We ensure safe and professional tree removal services for every Columbus
                    property.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Contact Us for Tree Removal Services</Heading>
                <Text>For top-notch tree removal services in Columbus, OH, contact Columbus Tree Service. Call us
                    at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> to schedule a service.</Text>
            </Stack>
        </Box>
    },
    "treeTrimming": {
        "url": 'https://www.topcolumbustreeservice.com/tree-trimming/',
        "metaData": {
            "title": 'Professional Tree Trimming in Columbus, OH | Columbus Tree Service',
            "description": 'Enhance the health and beauty of your trees with our professional tree trimming services in Columbus, OH. Contact us for expert care!'
        },
        "h1": 'Tree Trimming Services in Columbus, OH',
        "subtitle": 'Keeping your trees healthy and attractive with our professional tree trimming services in Columbus. Our experienced arborists use the best practices to ensure the optimal health of your trees.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Tree Trimming Services</Heading>
                <Text>At Columbus Tree Service, we offer specialized tree trimming services, including health trimming
                    to address unhealthy branches, structural trimming for tree strength, aesthetic trimming for visual
                    appeal, and safety trimming to mitigate risks.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Choose Columbus Tree Service?</Heading>
                <Text>With expert arborists and a commitment to quality service, Columbus Tree Service is your ideal
                    partner for tree trimming. We combine local expertise and a focus on customer satisfaction to
                    deliver excellent service.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Schedule Your Tree Trimming Service</Heading>
                <Text>Contact Columbus Tree Service today at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> for
                    professional tree trimming in Columbus, OH.</Text>
            </Stack>
        </Box>
    },
    "stumpGrinding": {
        "url": 'https://www.topcolumbustreeservice.com/stump-grinding',
        "metaData": {
            "title": 'Efficient Stump Grinding in Columbus, OH | Columbus Tree Service',
            "description": "Remove unsightly tree stumps with our efficient stump grinding services in Columbus, OH. Columbus Tree Service offers professional solutions for a neat and safe landscape."
        },
        "h1": 'Stump Grinding Services in Columbus, OH',
        "subtitle": 'Columbus Tree Service provides efficient stump grinding solutions, helping to enhance the appearance and safety of your property in Columbus. Our services are designed to effectively remove tree stumps, leaving your landscape clean and ready for new growth.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Stump Grinding Services</Heading>
                <Text>Our comprehensive stump grinding services include the removal of tree stumps of all sizes,
                    ensuring a clean and hazard-free landscape. We employ advanced equipment and techniques for
                    effective stump grinding and debris removal, followed by landscape restoration if needed.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Choose Columbus Tree Service?</Heading>
                <Text>Choose Columbus Tree Service for experienced professionals, quality service, local expertise, and
                    commitment to customer satisfaction. Our team ensures a smooth and efficient stump grinding process,
                    tailored to your specific landscape needs in Columbus.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Contact Us for Stump Grinding Services</Heading>
                <Text>For professional stump grinding services in Columbus, OH, reach out to Columbus Tree Service.
                    Contact us at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> to schedule a consultation or
                    service appointment.</Text>
            </Stack>
        </Box>
    },
    "treeHealthConsulting": {
        "url": 'https://www.topcolumbustreeservice.com/tree-health-consulting',
        "metaData": {
            "title": 'Professional Tree Health Consulting in Columbus, OH | Columbus Tree Service',
            "description": 'Ensure the health and vitality of your trees with expert consulting services from Columbus Tree Service. Our certified arborists provide comprehensive assessments and tailored care plans.'
        },
        "h1": 'Comprehensive Tree Health Consulting in Columbus, OH',
        "subtitle": 'Maximize the health and longevity of your trees with Columbus Tree Service. Our experienced arborists offer in-depth consulting to diagnose, treat, and maintain the health of your trees, tailored to the unique environment of Columbus.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Tree Health Consulting Services</Heading>
                <Text>Our services include thorough health assessments to identify any issues, development of
                    comprehensive nutrition plans, management of diseases and pests, and educational insights on tree
                    care practices. Our approach is tailored to the specific needs of each tree and landscape in
                    Columbus.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Columbus Tree Service for Tree Health?</Heading>
                <Text>Our certified arborists bring extensive experience and a personalized approach to every
                    consultation. We are equipped with comprehensive knowledge of local flora and a commitment to
                    sustainability and customer satisfaction.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Schedule Your Tree Health Consultation</Heading>
                <Text>Contact Columbus Tree Service for top-tier tree health consulting services. Reach us at <Link
                    href={phoneNumber}>{phoneNumberWithDashes}</Link> to schedule a consultation and ensure the
                    well-being of your trees.</Text>
            </Stack>
        </Box>
    },
    "landClearing": {
        "url": 'https://www.topcolumbustreeservice.com/land-clearing',
        "metaData": {
            "title": 'Expert Land Clearing Services in Columbus, OH | Columbus Tree Service',
            "description": 'Prepare your property for new projects with our professional land clearing services in Columbus, OH. Columbus Tree Service offers efficient and eco-friendly solutions.'
        },
        "h1": 'Land Clearing Services in Columbus, OH',
        "subtitle": 'Columbus Tree Service provides comprehensive land clearing services, ensuring your property in Columbus is prepared for new construction, landscaping, or agricultural use. Our team employs efficient and responsible methods for tree and brush removal, stump grinding, and debris disposal.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Land Clearing Services</Heading>
                <Text>Our land clearing services include the removal of trees, shrubs, and undergrowth, stump grinding
                    and removal, and responsible disposal of all vegetation and debris. We focus on efficiency and
                    environmental stewardship in every project.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Choose Columbus Tree Service?</Heading>
                <Text>Choose us for a comprehensive approach, eco-friendly practices, professional equipment, and
                    experienced team. We are committed to providing high-quality land clearing services while minimizing
                    environmental impact.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Contact Us for Land Clearing Services</Heading>
                <Text>For efficient and eco-friendly land clearing services in Columbus, OH, contact Columbus Tree
                    Service. Call us at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> for a consultation or to
                    schedule service.</Text>
            </Stack>
        </Box>
    },
    "arboristConsulting": {
        "url": 'https://www.topcolumbustreeservice.com/arborist-consultations',
        "metaData": {
            "title": 'Expert Arborist Consultations in Columbus, OH | Columbus Tree Service',
            "description": 'Benefit from the expertise of certified arborists for your trees in Columbus, OH. Columbus Tree Service provides professional advice on tree care, health, and maintenance.'
        },
        "h1": 'Arborist Consultation Services in Columbus, OH',
        "subtitle": 'At Columbus Tree Service, our certified arborists offer expert consulting services to address your tree care needs. From health assessments to landscape planning, our advice is grounded in extensive knowledge and a commitment to the health of your trees and environment.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Arborist Consulting Services</Heading>
                <Text>We provide tree health assessments, tree care recommendations, landscape planning, and risk
                    assessment. Each consultation is tailored to the specific needs of your trees and property in
                    Columbus.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Choose Columbus Tree Service for Arborist Consulting?</Heading>
                <Text>Our team offers certified and experienced arborists, personalized solutions, comprehensive
                    knowledge, and a commitment to sustainability. We are dedicated to providing the best arborist
                    consulting services in Columbus.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Schedule Your Arborist Consultation</Heading>
                <Text>For professional arborist consulting in Columbus, OH, contact Columbus Tree Service. Reach us
                    at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> to schedule an appointment.</Text>
            </Stack>
        </Box>
    },
    "treePlanting": {
        "url": 'https://www.topcolumbustreeservice.com/tree-planting',
        "metaData": {
            "title": 'Professional Tree Planting Services in Columbus, OH | Columbus Tree Service',
            "description": 'Enhance your landscape with our expert tree planting services in Columbus, OH. Our skilled team ensures healthy growth and proper care for your new trees.'
        },
        "h1": 'Tree Planting Services in Columbus, OH',
        "subtitle": 'Columbus Tree Service offers professional tree planting and transplanting services, ensuring the health and proper establishment of your trees. Our experienced arborists carefully select and plant trees that complement the Columbus landscape, focusing on long-term health and growth.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Tree Planting Services</Heading>
                <Text>Our services include professional tree planting, tree transplanting, and post-planting care. We
                    provide expert advice on suitable tree species for Columbus's climate and soil, ensuring proper
                    planting techniques for robust growth and tree health.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Choose Columbus Tree Service?</Heading>
                <Text>With our in-depth knowledge of local botany and commitment to environmental sustainability, we are
                    your go-to experts for tree planting in Columbus. We focus on customer satisfaction and the
                    longevity of your trees.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Contact Us for Tree Planting Services</Heading>
                <Text>To enhance your landscape with professional tree planting services in Columbus, OH, contact
                    Columbus Tree Service. Call us at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> for more
                    information or to schedule a service.</Text>
            </Stack>
        </Box>
    },
    "emergencyTreeServices": {
        "url": 'https://www.topcolumbustreeservice.com/emergency-tree-services',
        "metaData": {
            "title": '24/7 Emergency Tree Services in Columbus, OH | Columbus Tree Service',
            "description": 'Immediate response for emergency tree services in Columbus, OH. Contact Columbus Tree Service for urgent tree care and removal.'
        },
        "h1": 'Emergency Tree Services in Columbus, OH',
        "subtitle": 'Columbus Tree Service offers fast, reliable, and safe emergency tree care services in Columbus. We are equipped to handle urgent tree care needs, including storm damage, fallen trees, and other emergencies, ensuring the safety of your property and residents.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Emergency Tree Services</Heading>
                <Text>We provide rapid response to emergency situations, offering services like storm-damaged tree
                    management, emergency tree removal, and urgent tree care to address immediate safety concerns and
                    minimize potential hazards.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Choose Columbus Tree Service?</Heading>
                <Text>Our team is known for its swift response, expertise in handling emergencies, and commitment to
                    safety. We ensure prompt and effective solutions to emergency tree situations in Columbus.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Need Emergency Tree Services?</Heading>
                <Text>If you're facing a tree emergency in Columbus, OH, don't hesitate to contact Columbus Tree
                    Service. We're available 24/7 to provide immediate assistance. Call us at <Link
                        href={phoneNumber}>{phoneNumberWithDashes}</Link>.</Text>
            </Stack>
        </Box>
    },
    "404": {
        "metaData": {
            "title": 'Page Not Found - Columbus Tree Service | Tree Care',
            "description": 'Oops! The page you\'re seeking could not be found. Columbus Tree Service offers professional tree care services. Reach out to us for reliable help.',
            "siteName": 'Tree Service in Columbus, OH',
        },
        "h1": '404 - Oops, looks like this page is missing.',
        "subtitle": 'Unfortunately, the page you are looking for does not exist or has been moved.',
        "p": 'If you’re looking for tree trimming, stump removal, or any other tree care service, we’ve got you covered. Call Columbus Tree Service for your tree care needs in Columbus, OH.',
    }
}